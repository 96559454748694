@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  background-image: url("../src/images/pink-icing-cupcake.jpg");
  background-size: cover;
  background-position: top center;
}
/* 
.product-banner {
  background-image: url("../src/images/pexels-shvets-production-7525125.jpg");
  background-position: top center;
  height: 200px;
} */
/* 
.landing-page-cover {
  background-size: cover;
  background-position: center;
  background-image: url("/src/images/pexels-julias-torten-und-törtchen-1179002.jpg");
} */
